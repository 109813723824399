import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
//import FindDealer from '../components/distributor/component.find-dealer';
import Distributor from '../components/distributor/component.distributor';
import HeroDistributor from '../components/distributor/component.hero-distributor';
import useFullDealersData from "../hooks/hook.useFullDealersData";
import ComponentParser from '../components/component.parser';
import HeroBanner from '../components/hero-banner/component.banner';
import Content from '../components/component.content';
import CookieNotice from "../components/component.cookie-notice";
import Axios from "axios";

/**
 * Order the distributors alphabetically by country
 *
 * @param {*} list
 * @returns
 */

function sortAlphabeticallyByCountry(list) {
    const orderedList = list.sort(function(a, b){
        if (a.ACFDealerBlockFields.country < b.ACFDealerBlockFields.country) return -1;
        if (a.ACFDealerBlockFields.country > b.ACFDealerBlockFields.country) return 1;
        return 0;
    });

    return orderedList;
}

const Distributors = ({ data }) => {
    const dealersData = useFullDealersData();
    const [dealers, setDealers] = useState([]);
    const [loadLocation, setLoadLocation] = useState([]);
    const { title, content, blocks, featuredImage, ACFFeaturedVideoBlockFields: video, seo, slug } = data.wordpress.page;

    const bannerImage = featuredImage ? featuredImage.node.imageFile : false;

    useEffect(() => {
        // MaxMind code
        // https://dev.maxmind.com/geoip/geolocate-an-ip/client-side-javascript?lang=en
        // if testing, add Gatsby Cloud build URL to https://www.maxmind.com/en/accounts/658737/geoip/javascript/domains
        // live account is https://www.maxmind.com/en/accounts/673337/geoip/javascript/domains
        // log in developers@absolute... in Last Pass

        // check if stored in localStorage:
        let requiresGeoIPLookup = true;
        if (typeof window !== 'undefined' && window) {
            if (localStorage.getItem("storedCountry") !== null) {
                requiresGeoIPLookup = false;
                setLoadLocation(localStorage.getItem("storedCountry"));
            }
        }

        if(requiresGeoIPLookup) {
            // https://ipapi.co/api/
            Axios.get("https://ipapi.co/json/")
            .then(result => {
                setLoadLocation(result.data.country_name);
                if (typeof window !== 'undefined' && window) {
                    localStorage.setItem('storedCountry',result.data.country_name);
                }
                setDealers(dealersData);
            })
            .catch(err => {
                // just use alphabetical then:
                const orderedDealers = sortAlphabeticallyByCountry(dealersData);
                setDealers(orderedDealers);
            })
        }

        setDealers(dealersData);
        
    }, []);
    
    return (
        <Layout>
            <SEO title={title} seo={seo} image={featuredImage} slug={slug} templateClass={'distributor'} />
            <HeroBanner title={title} image={bannerImage} video={video} emphasis={true} />

            <div className="xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
                <div className="row">
                    {dealers.slice(0,1).map((dealer, index) => {
                        return (
                            <div key={index} className="col-md-12 c-distributor-grid">
                                <HeroDistributor distributor={dealer} banner={featuredImage} dealers={dealers} setDealers={setDealers} loadLocation={loadLocation} setLoadLocation={setLoadLocation} extendedDisplay={true} />
                            </div>
                        );
                    })}
                </div>
                <div className="container">
                    <div className="row">
                        {dealers.slice(1).map((dealer, index) => {
                            return (
                                <div key={index} className="col-lg-4 col-md-6 c-distributor-grid">
                                    <Distributor distributor={dealer} />
                                </div>
                            );
                        })}
                    </div>
                    {content &&
                        <Content full content={content} />
                    }
                    {blocks &&
                        <ComponentParser blocks={blocks} />
                    }
                </div>
            </div>
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            page(id: $id) {
                title
                content
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                ...PageBlocks
                seo {
                    ...SeoPostFields
                }
            }
        }
    }
`;

export default Distributors;
